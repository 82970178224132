interface TopicProps {
  index: number
  text: string
}

export function Topic({...props}: TopicProps) {
  return(
    <div className="relative mb-8 flex items-center">
      <div className="absolute -left-4 -top-1 bg-background-color flex items-center justify-center border-4 border-primary-500 rounded-full w-14 h-14 text-2xl">
        <span>{props.index}</span>
      </div>

      <div className="pl-12 pr-4 w-full py-2 border-2 rounded-lg border-primary-500">
        <span>{props.text}</span>
      </div>
    </div>
  )
}